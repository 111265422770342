'use strict';
var src = require('../braintreeSrc');
var srcHelper = require('./srcHelper');

var $srcButton = document.querySelector('.js_braintree_src_button');
var $srcNonce = document.querySelector('#braintreeSRCNonce');
var $srcCardDescription = document.querySelector('#braintreeSrcCardDescription');
var $srcBillingAddress = document.querySelector('#braintreeSrcBillingAddress');
var $srcAccountList = document.querySelector('#braintreeSrcAccountsList');
var $srcOnCart = document.querySelector('.braintree-cart-src-button');

function initSrcButton(continueButton) {
    var srcIns;
    var config = JSON.parse($srcButton.getAttribute('data-braintree-config'));
    if (typeof config !== 'object' || config === null) {
        // eslint-disable-next-line no-console
        console.error($srcButton, 'not valid data-braintree-config');
    }

    if ($srcAccountList) {
        srcHelper.initSessionAccountDropdownState();
        $srcAccountList.addEventListener('change', srcHelper.updateSessionAccountDropdown);
    }

    config.onTokenizePayment = function (data) {
        require('../helper').removeActiveSessionPayment();
        var cardDescription = data.details.cardType + ' ' + data.description;
        var deviceData = document.querySelector('input[name=braintreeSrcDeviceDataInput]').value;

        if ($srcOnCart) {
            var shippingAddressData = srcHelper.createSrcShippingAddressData(data.shippingAddress);

            $.ajax({
                url: config.returnFromCartUrl,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify({
                    nonce: data.nonce,
                    paymentCardDescription: cardDescription,
                    billingAddress: data.billingAddressString,
                    shippingAddress: shippingAddressData || {},
                    deviceData: deviceData
                }),
                success: function (res) {
                    sessionStorage.setItem('pageState', 'cart');
                    srcIns.loader.hide();
                    window.location.href = res.redirectUrl;
                },
                error: function () {
                    srcIns.loader.hide();
                }
            });
            return;
        }

        $srcNonce.value = data.nonce;
        $srcCardDescription.value = cardDescription;
        $srcBillingAddress.value = data.billingAddressString;

        srcHelper.setSessionAccountData(cardDescription);

        continueButton.click();
        return;
    };

    srcIns = src.init(config, $srcButton);
    srcIns.loadSrcButton();

    $('body').on('checkout:updateCheckoutView', srcHelper.srcUpdateAmount.bind(null, srcIns, config));
    $('body').on('braintree:updateCartTotals', srcHelper.srcUpdateAmount.bind(null, srcIns, config));
    srcHelper.srcUpdateAmount(srcIns, config);
}

module.exports = {
    initSrcButton
};
